<template>
  <div class="top-section">
    <h2>Best Savings Accounts</h2>
    <p class="text-muted">Find the perfect spot for it to relax and grow!</p>
  </div>
  <div class="main-content">
    <div class="filter-panel">
      <div class="filter-bar">
        <h2 class="filter-bar-header">
          Your Balance (SGD)
        </h2>
        <div class="filter-bar-block">
          <div class="field_wrap">
            <label for="balance" class="input_label">Balance</label>
            <input type="number" v-model.number="balance" placeholder="Enter balance" />
          </div>
        </div>

      </div>

      <div class="filter-bar">
        <h2 class="filter-bar-header">
          Conditions
        </h2>
        <div class="filter-bar-block">
          <b-form-checkbox-group
            v-model="conditions_selected"
            :options="conditions"
            class="mb-3 form-check"
            value-field="value"
            text-field="text"
          ></b-form-checkbox-group>
        </div>
      </div>

      <div class="filter-bar">
        <h2 class="filter-bar-header">
          Providers
        </h2>
        <div class="filter-bar-block">
          <b-form-checkbox-group
            v-model="providers_selected"
            :options="providers"
            class="mb-3 form-check"
            value-field="value"
            text-field="text"
            @change="handleProviderChange()"
          ></b-form-checkbox-group>
        </div>
      </div>

    </div>
    <div class="listing-panel">
      <div v-for="account in filteredAccounts" :key="account.id" class="listing-bar mb-4">
        <div class="listing-details">
          <div class="listing-header">
            <div class="listing-logo">
              <img :src="require(`@/assets/icons/${account.logo}`)" :alt="`${account.title} Logo`">
            </div>
            <span class="listing-title">{{ account.title }}</span>
          </div>
          <div class="listing-body">
            <ul class="listing-body-content mb-4">
              <li v-for="condition in account.conditions" :key="condition.title">
                <div class="title" v-html="condition.title"></div>
                <div class="value">{{ condition.value }}</div>
              </li>
            </ul>

            <b-card no-body class="no-border">
              <b-tabs pills class="bg-white">
                <b-tab v-for="(tab, index) in account.tabs" :key="index" :title="tab.title" :active="index === 0">
                  <b-card-text class="mt-4">
                    <b-table :hover="true" :items="tab.items">
                      <template v-slot:cell(text)="data">
                        <div v-html="data.item.text"></div>
                      </template>
                    </b-table>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
        <div class="listing-right">
          <div class="listing-text">
            <span class="right-label">REALISTIC RATE</span>
            <h3>{{ account.realisticRate }}</h3>
          </div>
          <div class="listing-text">
            <span class="right-label">ESTIMATED INTEREST</span>
            <h3>{{ account.estimatedInterest }}</h3>
          </div>
          <div class="listing-text">
            <a :href="account.applyNowLink" class="btn btn-primary" target="_blank" rel="noopener noreferrer">APPLY NOW</a>
          </div>
        </div>
      </div>
      <div>
        <i class="fa-solid fa-money-bill-transfer"></i> <em><small>Eligible for self-credit salary transactions.</small></em>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckboxGroup, BTable, BCard, BTab, BTabs } from 'bootstrap-vue-3';

export default {
  name: 'Home',
  components: {
    BFormCheckboxGroup,
    BCard,
    BTabs,
    BTab,
    BTable
  },
  watch: {
    balance() {
      this.calculateOCBCInterest();
      this.calculateSCInterest();
      this.calculateUOBInterest();
      this.calculateBOCInterest();
      this.calculateHSBCInterest();
      this.calculateCIMBInterest();
      this.calculateMariBankInterest();
      this.calculateTrustInterest();
      this.calculateStashInterest();
      this.calculateEsaverInterest();
      this.calculateCFInterest();
      this.sortAccountsByRate();
    },
    conditions_selected() {
      this.calculateOCBCInterest();
      this.calculateSCInterest();
      this.calculateUOBInterest();
      this.calculateBOCInterest();
      this.calculateHSBCInterest();
      this.calculateCIMBInterest();
      this.calculateMariBankInterest();
      this.calculateTrustInterest();
      this.calculateStashInterest();
      this.calculateEsaverInterest();
      this.calculateCFInterest();
      this.sortAccountsByRate();
    }
  },
  computed: {
    filteredAccounts() {
      // Create an array to store the IDs of accounts to include
      let includedIds = [];

      // Map selected providers to their corresponding account IDs
      const providerToIdsMap = {
        'ocbc': [1],
        'boc': [4],
        'sc': [2, 11],
        'uob': [3, 10],
        'hsbc': [5],
        'cimb': [6],
        'cf': [7],
        'mari': [8],
        'trust': [9]
      };

      // Loop through selected providers and gather corresponding account IDs
      this.providers_selected.forEach(provider => {
        if (providerToIdsMap[provider]) {
          includedIds = includedIds.concat(providerToIdsMap[provider]);
        }
      });
      // Return filtered accounts that match the gathered IDs
      return this.accounts.filter(account => includedIds.includes(account.id));
    }
  },
  methods: {
    handleProviderChange() {
      const added = this.providers_selected.filter(x => !this.previousState.includes(x));
      const removed = this.previousState.filter(x => !this.providers_selected.includes(x));

      if (removed.includes('all')) {
        this.providers_selected = [];
      } 

      if (added.includes('all')) {
        this.providers_selected = [
          "all", "boc", "cf", "cimb", "dbs", "hsbc", "mari", "ocbc", "sc", "trust", "uob"
        ];
      }
      if (this.providers_selected.includes('all')) {
        if (this.providers_selected.length === this.providers.length) {
          this.providers_selected = [
            "all", "boc", "cf", "cimb", "dbs", "hsbc", "mari", "ocbc", "sc", "trust", "uob"
          ];
        } else {
          this.providers_selected = this.providers_selected.filter(value => value !== 'all');
        }
      } else {
        if (this.providers_selected.length === this.providers.length - 1) {
          if (!this.providers_selected.includes('all')) {
            this.providers_selected.push('all');
          }
        }
      }
      this.previousState = [...this.providers_selected];
    },
    sortAccountsByRate() {
      this.accounts.sort((a, b) => {
        const rateA = parseFloat(a.realisticRate.replace('%', ''));
        const rateB = parseFloat(b.realisticRate.replace('%', ''));
        return rateB - rateA;
      });
    },
    calculateCFInterest() {
      const firstTierRate = 0.042; 
      const secondTierRate = 0.035; 
      const firstTierCap = 20000; 

      let interest = 0;

      if (this.balance > firstTierCap) {
        interest += firstTierCap * firstTierRate;
        interest += (this.balance - firstTierCap) * secondTierRate;
      } else {
        interest += this.balance * firstTierRate;
      }
      interest = interest / 365 * 31;
      this.accounts = this.accounts.map(account => {
        if (account.id === 7) {
          return {
            ...account,
            realisticRate: `${((interest / this.balance) * 365 / 31 * 100).toFixed(2)}%`,
            estimatedInterest: `S$${interest.toFixed(2)}` 
          };
        }
        return account;
      });
    },

    calculateEsaverInterest() {
      const esaverInterestRate = 0.0335; 
      let interest = this.balance * esaverInterestRate;
      interest = interest / 365 * 31;
      this.accounts = this.accounts.map(account => {
        if (account.id === 11) { 
          return {
            ...account,
            realisticRate: `${(esaverInterestRate * 100).toFixed(2)}%`,
            estimatedInterest: `S$${interest.toFixed(2)}`
          };
        }
        return account;
      });``
    },
    calculateStashInterest() {
      const balance = this.balance;
      let interest = 0;

      // Interest tiers
      const tier1Cap = 10000;
      const tier2Cap = 30000;
      const tier3Cap = 30000;
      const tier4Cap = 30000;

      // Interest rates
      const tier1Rate = 0.0005; // 0.05% for the first S$10,000
      const tier2Rate = 0.02;   // 2.00% for the next S$30,000
      const tier3Rate = 0.03;   // 3.00% for the next S$30,000
      const tier4Rate = 0.05;   // 5.00% for the next S$50,000
      const tier5Rate = 0.0005; // 0.05% for the balance above S$100,000

      // Calculate interest based on tiers
      if (balance > 100000) {
        interest += (balance - 100000) * tier5Rate;
        interest += tier4Cap * tier4Rate;
        interest += tier3Cap * tier3Rate;
        interest += tier2Cap * tier2Rate;
        interest += tier1Cap * tier1Rate;
      } else if (balance > 70000) {
        interest += (balance - 70000) * tier4Rate;
        interest += tier3Cap * tier3Rate;
        interest += tier2Cap * tier2Rate;
        interest += tier1Cap * tier1Rate;
      } else if (balance > 20000) {
        interest += (balance - 20000) * tier3Rate;
        interest += tier2Cap * tier2Rate;
        interest += tier1Cap * tier1Rate;
      } else if (balance > 10000) {
        interest += (balance - 10000) * tier2Rate;
        interest += tier1Cap * tier1Rate;
      } else {
        interest += balance * tier1Rate;
      }

      let maxRate = interest / balance;
      interest = interest / 365 * 31;

      this.accounts = this.accounts.map(account => {
        if (account.id === 10) {
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`,
            estimatedInterest: `S$${interest.toFixed(2)}`
          };
        }
        return account;
      });
    },
    calculateTrustInterest() {
      const balance = this.balance;
      let baseInterestRate = 0.0125;
      
      const calculateConditionInterest = (condition) => {
        let conditionInterest = 0;
        if (condition === 'salary') {
          conditionInterest = balance * 0.0075;
        } else if (condition === 'spend') {
          conditionInterest = balance * 0.01;
        } 
        return conditionInterest;
      };

      let interest = balance * baseInterestRate;

      if (balance > 100000) {
        interest += balance * 0.005;
      }

      this.conditions_selected.forEach(condition => {
        interest += calculateConditionInterest(condition);
      });

      let maxRate =  interest / balance;

      interest = interest / 365 * 31;
      this.accounts = this.accounts.map(account => {
        if (account.id === 9) {
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`,
            estimatedInterest: `S$${interest.toFixed(2)}`
          };
        }
        return account;
      });
    },
    calculateMariBankInterest() {
      const balance = this.balance;
      let interest = 0;
      if (balance <= 100000) {
        interest = balance * 0.027;
      } else {
        interest = 100000 * 0.027;
      }

      let maxRate =  interest / balance;

      interest = interest / 365 * 31;
      this.accounts = this.accounts.map(account => {
        if (account.id === 8) {
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`,
            estimatedInterest: `S$${interest.toFixed(2)}`
          };
        }
        return account;
      });
    },
    calculateCIMBInterest() {
      const balance = this.balance;
      const baseInterestRate = balance < 5000 ? 0.015 : 0.033; 

      // const next25kInterestRate = 0.035;
      // const above75kInterestRate = 0.033;

      let interest = 0;

      // if (balance > 75000) {
      //   interest += (balance - 75000) * above75kInterestRate;
      //   interest += 25000 * next25kInterestRate;
      //   interest += 50000 * baseInterestRate;
      // } else if (balance > 50000) {
      //   interest += (balance - 50000) * next25kInterestRate;
      //   interest += 50000 * baseInterestRate;
      // } else 
      if (balance >= 5000) {
        interest += balance * baseInterestRate;
      } else {
        interest += balance * 0.015; // 1.5% for balances less than 5000
      }

      const calculateConditionInterest = (condition) => {
        let conditionInterest = 0;
        if (condition === 'salary') {
          const eligibleBalance = Math.min(balance, 25000);
          conditionInterest = eligibleBalance * 0.005; // 0.5% additional interest for salary credit
        }
        return conditionInterest;
      };

      // Check for conditions like salary credit
      this.conditions_selected.forEach(condition => {
        interest += calculateConditionInterest(condition);
      });

      // Calculate the effective interest rate and estimated interest
      let maxRate = interest / balance;
      interest = interest / 365 * 31; // Assuming monthly interest calculation

      // Update the account's realistic rate and estimated interest
      this.accounts = this.accounts.map(account => {
        if (account.id === 6) { // Assuming 5 is the ID for CIMB
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`,
            estimatedInterest: `S$${interest.toFixed(2)}`
          };
        }
        return account;
      });
    },
    calculateHSBCInterest(){
      const balance = this.balance;
      const baseInterestRate = 0.0005;
      const incrementalInterestRate = 0.031;

      const calculateConditionInterest = (condition) => {
        let conditionInterest = 0;
        if (condition === 'transaction') {
          conditionInterest = balance * 0.01;
        }
        return conditionInterest;
      };

      let interest = this.balance * baseInterestRate;
      interest += this.balance * incrementalInterestRate;

      this.conditions_selected.forEach(condition => {
        interest += calculateConditionInterest(condition);
      });

      let maxRate =  interest / balance;

      interest = interest / 365 * 31;
      this.accounts = this.accounts.map(account => {
        if (account.id === 5) {
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`,
            estimatedInterest: `S$${interest.toFixed(2)}`
          };
        }
        return account;
      });
    },
    calculateOCBCInterest() {
      const baseInterestRate = 0.0005; // 0.05%
      const salaryInterestRate1 = 0.02; // 2%
      const salaryInterestRate2 = 0.04; // 4%
      const saveInterestRate1 = 0.012; // 1.2%
      const saveInterestRate2 = 0.024; // 2.4%
      const spendInterestRate = 0.006; // 0.6%
      const investOrInsureInterestRate1 = 0.012; // 1.2%
      const investOrInsureInterestRate2 = 0.024; // 2.4%
      const bonusRateForHighBalance = 0.024; // 2.40% p.a. for balance > $100,000 if balance >= $200,000

      const balance = this.balance;
      const cap = 100000; // $100,000 cap
      const threshold = 75000; // $75,000 threshold

      let interest = balance * baseInterestRate / 365 * 31; // Base interest for the entire balance

      const calculateConditionInterest = (condition) => {
        let conditionInterest = 0;

        if (condition === 'salary') {
          if (balance > cap) {
            conditionInterest += (threshold * salaryInterestRate1 + (cap - threshold) * salaryInterestRate2) / 365 * 31;
          } else if (balance > threshold) {
            conditionInterest += (threshold * salaryInterestRate1 + (balance - threshold) * salaryInterestRate2) / 365 * 31;
          } else {
            conditionInterest += (balance * salaryInterestRate1) / 365 * 31;
          }
        } else if (condition === 'save') {
          if (balance > cap) {
            conditionInterest += (threshold * saveInterestRate1 + (cap - threshold) * saveInterestRate2) / 365 * 31;
          } else if (balance > threshold) {
            conditionInterest += (threshold * saveInterestRate1 + (balance - threshold) * saveInterestRate2) / 365 * 31;
          } else {
            conditionInterest += (balance * saveInterestRate1) / 365 * 31;
          }
        } else if (condition === 'spend') {
          conditionInterest += Math.min(balance, cap) * spendInterestRate / 365 * 31;
        } else if (condition === 'invest' || condition === 'insure') {
          if (balance > cap) {
            conditionInterest += (threshold * investOrInsureInterestRate1 + (cap - threshold) * investOrInsureInterestRate2) / 365 * 31;
          } else if (balance > threshold) {
            conditionInterest += (threshold * investOrInsureInterestRate1 + (balance - threshold) * investOrInsureInterestRate2) / 365 * 31;
          } else {
            conditionInterest += (balance * investOrInsureInterestRate1) / 365 * 31;
          }
        }

        return conditionInterest;
      };

      this.conditions_selected.forEach(condition => {
        interest += calculateConditionInterest(condition);
      });

      if (balance >= 200000) {
        interest += (balance - cap) * bonusRateForHighBalance / 365 * 31; // Additional 2.4% for balance above $100,000 if balance >= $200,000
      }

      const maxRate = interest / balance * 365 / 31;

      // Update the OCBC account with the new calculated interest
      this.accounts = this.accounts.map(account => {
        if (account.id === 1) {
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`, // Highest applicable rate
            estimatedInterest: `S$${interest.toFixed(2)}` // Total interest
          };
        }
        return account;
      });
    },
    calculateSCInterest() {
      const baseInterestRate = 0.0005; // 0.05%
      const salaryInterestRate = 0.02; // 2%
      const spendInterestRate = 0.006; // 0.6%
      const billInterestRate = 0.0023; // 0.23%
      const investInterestRate = 0.02; // 2%
      const insureInterestRate = 0.02; // 2%

      const cap = 100000; // $100,000 cap

      let interest = this.balance * baseInterestRate; // Base interest

      const balance = this.balance;

      const calculateConditionInterest = (condition) => {
        let conditionInterest = 0;
        if (condition === 'salary') {
          if (balance > cap) {
            conditionInterest += cap * salaryInterestRate;
          } else {
            conditionInterest += balance * salaryInterestRate;
          }
        } else if (condition === 'spend') {
          if (balance > cap) {
            conditionInterest += cap * spendInterestRate;
          } else {
            conditionInterest += balance * spendInterestRate;
          }
        } else if (condition === 'bill') {
          if (balance > cap) {
            conditionInterest += cap * billInterestRate;
          } else {
            conditionInterest += balance * billInterestRate;
          }
        } else if (condition === 'invest') {
          if (balance > cap) {
            conditionInterest += cap * investInterestRate;
          } else {
            conditionInterest += balance * investInterestRate;
          }
        } else if (condition === 'insure') {
          if (balance > cap) {
            conditionInterest += cap * insureInterestRate;
          } else {
            conditionInterest += balance * insureInterestRate;
          }
        }
        return conditionInterest;
      };

      this.conditions_selected.forEach(condition => {
        interest += calculateConditionInterest(condition);
      });

      let maxRate = interest / balance;

      interest = interest / 365 * 31;

      // Update the SC account with the new calculated interest
      this.accounts = this.accounts.map(account => {
        if (account.id === 2) {
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`, // Highest applicable rate
            estimatedInterest: `S$${interest.toFixed(2)}` // Total interest
          };
        }
        return account;
      });
    },
    calculateUOBInterest() {
      const baseInterestRate = 0.0005; // 0.05%
      const salaryInterestRate1 = 0.03; // 3%
      const salaryInterestRate2 = 0.045; // 4.5%
      const salaryInterestRate3 = 0.06; // 6%
      const spendInterestRate1 = 0.0065; // 0.65%
      const spendInterestRate2 = 0.0005; // 0.05%
      
      const salaryCap1 = 75000; // $75,000
      const salaryCap2 = 125000; // $125,000
      const salaryCap3 = 150000; // $150,000
      const spendCap1 = 75000; // $75,000

      let interest = this.balance * baseInterestRate; // Base interest

      const balance = this.balance;

      const calculateSalaryInterest = () => {
        let salaryInterest = 0;
        if (balance > salaryCap3) {
          salaryInterest += (balance - salaryCap3) * baseInterestRate;
          salaryInterest += (salaryCap3 - salaryCap2) * salaryInterestRate3;
          salaryInterest += (salaryCap2 - salaryCap1) * salaryInterestRate2;
          salaryInterest += salaryCap1 * salaryInterestRate1;
        } else if (balance > salaryCap2) {
          salaryInterest += (balance - salaryCap2) * salaryInterestRate3; //25k
          salaryInterest += (salaryCap2 - salaryCap1) * salaryInterestRate2; // 50k
          salaryInterest += salaryCap1 * salaryInterestRate1;
        } else if (balance > salaryCap1) {
          salaryInterest += (balance - salaryCap1) * salaryInterestRate2;
          salaryInterest += salaryCap1 * salaryInterestRate1;
        } else {
          salaryInterest += balance * salaryInterestRate1;
        }
        return salaryInterest;
      };

      const calculateSpendInterest = () => {
        let spendInterest = 0;
        if (balance > spendCap1) {
          spendInterest += (balance - spendCap1) * spendInterestRate2;
          spendInterest += (spendCap1) * spendInterestRate1;
        } else {
          spendInterest += balance * spendInterestRate1;
        }
        return spendInterest;
      };

      if (this.conditions_selected.includes('spend')) {
        if (this.conditions_selected.includes('salary')) {
          interest = calculateSalaryInterest();
        } else {
          interest = calculateSpendInterest();
        }
      } 

      let maxRate = interest / balance;
      interest = interest / 365 * 31;

      // Update the UOB account with the new calculated interest
      this.accounts = this.accounts.map(account => {
        if (account.id === 3) {
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`, // Highest applicable rate
            estimatedInterest: `S$${interest.toFixed(2)}` // Total interest
          };
        }
        return account;
      });
    },
    calculateBOCInterest() {
      const baseRateBelow5k = 0.0015; // 0.15%
      const baseRateBelow20k = 0.002;  // 0.20%
      const baseRateBelow50k = 0.003;  // 0.30%
      const baseRateBelow100k = 0.003; // 0.30%
      const baseRateAbove100k = 0.004; // 0.40%
      const extraRateAbove100k = 0.006; // 0.60%

      const insureInterestRate = 0.024; // 2.4%
      const cardSpendInterestRate = 0.005; // 0.5%
      const salaryCreditInterestRate = 0.019; // 1.9%
      const billPaymentInterestRate = 0.009; // 0.9%

      const cap = 100000; // $100,000 cap

      let baseRate;
      if (this.balance < 5000) {
        baseRate = baseRateBelow5k;
      } else if (this.balance < 20000) {
        baseRate = baseRateBelow20k;
      } else if (this.balance < 50000) {
        baseRate = baseRateBelow50k;
      } else if (this.balance < 100000) {
        baseRate = baseRateBelow100k;
      } else {
        baseRate = baseRateAbove100k;
      }

      // Calculate base interest
      let interest = this.balance * baseRate;

      // Determine if bonus interest applies
      const hasBonusCondition = this.conditions_selected.includes('insure') ||
                                this.conditions_selected.includes('spend') ||
                                this.conditions_selected.includes('salary') ||
                                this.conditions_selected.includes('bill');

      // Calculate bonus interest
      if (hasBonusCondition) {
        if (this.balance > cap) {
          // Interest for the amount up to cap
          interest += (this.balance - cap) * extraRateAbove100k;
          if (this.conditions_selected.includes('insure')) {
            interest += cap * insureInterestRate;
          }
          if (this.conditions_selected.includes('spend')) {
            interest += cap * cardSpendInterestRate;
          }
          if (this.conditions_selected.includes('salary')) {
            interest += cap * salaryCreditInterestRate;
          }
          if (this.conditions_selected.includes('bill')) {
            interest += cap * billPaymentInterestRate;
          }
        } else {
          if (this.conditions_selected.includes('insure')) {
            interest += this.balance * insureInterestRate;
          }
          if (this.conditions_selected.includes('spend')) {
            interest += this.balance * cardSpendInterestRate;
          }
          if (this.conditions_selected.includes('salary')) {
            interest += this.balance * salaryCreditInterestRate;
          }
          if (this.conditions_selected.includes('bill')) {
            interest += this.balance * billPaymentInterestRate;
          }
        }
      }

      let maxRate = interest / this.balance;
      interest = interest / 365 * 31;
      // Update the BOC account with the new calculated interest
      this.accounts = this.accounts.map(account => {
        if (account.id === 4) {
          return {
            ...account,
            realisticRate: `${(maxRate * 100).toFixed(2)}%`, // Highest applicable rate
            estimatedInterest: `S$${interest.toFixed(2)}` // Total interest
          };
        }
        return account;
      });
    }
  },
  data() {
    return {
      balance: 100000,
      previousState: [
        "all",
        "boc",
        "cf",
        "cimb",
        "dbs",
        "hsbc",
        "mari",
        "ocbc",
        "sc",
        "trust",
        "uob"
      ],
      providers_selected: [
          "all",
          "boc",
          "cf",
          "cimb",
          "dbs",
          "hsbc",
          "mari",
          "ocbc",
          "sc",
          "trust",
          "uob"
      ],
      providers: [
        { text: 'All Providers', value:'all'},
        { text: 'BOC', value:'boc'},
        { text: 'Chocolate Finance', value:'cf'},
        { text: 'CIMB', value:'cimb'},
        { text: 'DBS', value:'dbs'},
        { text: 'HSBC', value:'hsbc'},
        { text: 'Maribank', value:'mari'},
        { text: 'OCBC', value:'ocbc'},
        { text: 'Standard Chartered', value:'sc'},
        { text: 'Trust Bank', value:'trust'},
        { text: 'UOB', value:'uob'},
      ],
      conditions_selected: ['salary', 'spend', 'save', 'bill'],
      conditions: [
        { text: 'Credit a salary via GIRO/Paynow', value:'salary'},
        { text: 'Spend at least S$500 on card', value:'spend'},
        { text: 'Increased average daily balance at least S$500', value:'save'},
        { text: 'Make at least 3 bill payments', value:'bill'},
        { text: 'Make at least 5 transactions', value:'transaction'},
        { text: 'Invest in eligible products', value:'invest'},
        { text: 'Insure in eligible products', value:'insure'},
      ],
      accounts: [
        {
          id: 1,
          logo: 'ocbc.png',
          title: '360 Account',
          conditions: [
            { title: 'SALARY', value: 'Credit your salary of min. S$1,800.' },
            { title: 'SAVE', value: 'Increase your average daily balance by at least $500.' },
            { title: 'SPEND', value: 'Charge your OCBC card at least S$500.' }
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: '<strong class="num">2.00%</strong> p.a. interest for first <strong class="num">S$75,000</strong> and <strong class="num">4.00%</strong> p.a. interest for next <strong class="num">S$25,000</strong> with a minimum salary credit of <strong class="num">S$1,800</strong>' },
                { index: 2, text: '<strong class="num">1.20%</strong> p.a. interest for first <strong class="num">S$75,000</strong> and <strong class="num">2.40%</strong> p.a. interest for next <strong class="num">S$25,000</strong> with a minimum increase on daily balance by <strong class="num">S$500</strong>' },
                { index: 3, text: '<strong class="num">0.60%</strong> p.a. interest with a minimum <strong class="num">S$500</strong> on eligible card spends' },
                { index: 4, text: '<strong class="num">1.20%</strong> p.a. interest for first <strong class="num">S$75,000</strong> and <strong class="num">2.40%</strong> p.a. interest for next <strong class="num">S$25,000</strong> with a minimum <strong class="num">S$2,000</strong> annual premium for an Eligible Insurance Policy' },
                { index: 5, text: '<strong class="num">1.20%</strong> p.a. interest for first <strong class="num">S$75,000</strong> and <strong class="num">2.40%</strong> p.a. interest for next <strong class="num">S$25,000</strong> with a minimum <strong class="num">S$20,000</strong> subscription sum for an Eligible Unit Trust or Structured Deposits' },
                { index: 6, text: '<strong class="num">2.40%</strong> p.a. interest maintain an average daily balance of at least <strong class="num">S$200,000</strong>' },
                { index: 7, text: 'Bonus interest cap on the first <strong class="num">S$100,000</strong> of your account balance' },
              ]
            },
            {
              title: 'New User',
              items: [
                { index: 1, text: '<strong class="num">4.00%</strong> p.a. interest on the first <strong class="num">S$75,000</strong> balance for the first 2 months after the month of account opening with a minimum salary credit of <strong class="num">S$1,800</strong> per month' },
                { index: 2, text: '<strong class="num">1.20%</strong> p.a. interest on the first <strong class="num">S$75,000</strong> balance for the first 2 months after the month of account opening with a minimum <strong class="num">S$500</strong> on eligible card spends' },
              ]
            },
          ],
          realisticRate: '4.65%',
          estimatedInterest: 'S$394.93',
          applyNowLink: 'https://www.ocbc.com/personal-banking/deposits/360-savings-account'
        },
        {
          id: 4,
          logo: 'boc.png',
          title: 'SmartSaver Account',
          conditions: [
            { title: 'SALARY', value: 'Credit your salary of min. S$2,000.' },
            { title: 'SAVE', value: 'Increase your average daily balance by at least $500.' },
            { title: 'BILL', value: 'Make at least 3 bill payments online.' },
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: '<strong class="num">1.9%</strong> p.a. interest with a minimum salary credit of <strong class="num">S$2,000</strong><br/><strong class="num">2.5%</strong> p.a. interest with a minimum salary credit of <strong class="num">S$6,000</strong>' },
                { index: 2, text: '<strong class="num">0.50%</strong> p.a. interest with a minimum of <strong class="num">S$500</strong> on eligible card spends<br/><strong class="num">0.80%</strong> p.a. interest with a minimum of <strong class="num">S$1,500</strong> on eligible card spends' },
                { index: 3, text: '<strong class="num">0.90%</strong> p.a. interest for at least <strong class="num">3</strong> eligible bill payments made via GIRO or Online Banking, with a minimum of <strong class="num">S$30</strong> each' },
                { index: 4, text: '<strong class="num">2.40%</strong> p.a. interest with a minimum <strong class="num">S$12,000</strong> annual premium for an Eligible Insurance Policy' },
                { index: 5, text: '<strong class="num">0.60%</strong> p.a. interest for account balance above <strong class="num">S$100,000</strong>' },
                { index: 6, text: 'Bonus interest cap on the first <strong class="num">S$100,000</strong> of your account balance' },
              ]
            },
          ],
          realisticRate: '3.70%',
          estimatedInterest: 'S$314.25',
          applyNowLink: 'https://branch.ap.ebs.bankofchina.com/SG_MYINFO/ZMBS/ZMBSSG/index.html#/accountEntrance'
        },
        {
          id: 2,
          logo: 'sc.png',
          title: 'Bonu$aver Account',
          conditions: [
            { title: 'SALARY<i class="fa-solid fa-money-bill-transfer"></i>', value: 'Credit your salary of min. S$3,000.' },
            { title: 'SPEND', value: 'Charge your SC card at least S$500.' },
            { title: 'BILL', value: 'Make at least 3 bill payments online.' },
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: '<strong class="num">2.00%</strong> p.a. interest with a minimum salary credit of <strong class="num">S$3,000</strong> per month' },
                { index: 2, text: '<strong class="num">0.60%</strong> p.a. interest with a minimum of <strong class="num">S$500</strong> on eligible card spends<br/><strong class="num">1.40%</strong> p.a. interest with a minimum of <strong class="num">S$2,000</strong> on eligible card spends' },
                { index: 3, text: '<strong class="num">0.23%</strong> p.a. interest for at least <strong class="num">3</strong> eligible bill payments made via GIRO or Online Banking, with a minimum of <strong class="num">S$50</strong> each' },
                { index: 4, text: '<strong class="num">2.00%</strong> p.a. interest with a minimum <strong class="num">S$30,000</strong> subscription sum for an Eligible Unit Trust' },
                { index: 5, text: '<strong class="num">2.00%</strong> p.a. interest with a minimum <strong class="num">S$12,000</strong> annual premium for an Eligible Insurance Policy' },
                { index: 6, text: 'Bonus interest cap on the first <strong class="num">S$100,000</strong> of your account balance' },
              ]
            },
            {
              title: 'New User',
              items: [
                { index: 1, text: '<strong class="num">S$68</strong> - Deposit and maintain <strong class="num">S$50,000</strong> until the end of the calendar month immediately after the calendar month in which your Bonus$aver Account is opened' },
                { index: 2, text: '<strong class="num">S$100</strong> - Credit your salary within the first <strong class="num">2</strong> months of account opening' },
                { index: 3, text: '<strong class="num">S$30</strong> - Account opening using referral code <strong class="num">[77F98]</strong>' }
              ]
            },
          ],
          realisticRate: '2.88%',
          estimatedInterest: 'S$244.6',
          applyNowLink: 'https://www.sc.com/sg/save/current-accounts/bonussaver'
        },
        {
          id: 3,
          logo: 'uob.png',
          title: 'One Account',
          conditions: [
            { title: 'SPEND', value: 'Charge your UOB card at least S$500.' },
            { title: 'SALARY<i class="fa-solid fa-money-bill-transfer"></i>', value: 'Credit your salary of min. S$1,600.' },
            { title: '', value: '' },
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: '<strong class="num">3.00%</strong> p.a. interest for first <strong class="num">S$75,000</strong> account monthly average balance' },
                { index: 2, text: '<strong class="num">4.50%</strong> p.a. interest for next <strong class="num">S$50,000</strong> account monthly average balance' },
                { index: 3, text: '<strong class="num">6.00%</strong> p.a. interest for next <strong class="num">S$25,000</strong> account monthly average balance' },
                { index: 4, text: '<strong class="num">0.05%</strong> p.a. interest for above <strong class="num">S$150,000</strong> account monthly average balance' },
                { index: 5, text: 'Bonus interest cap on the first <strong class="num">S$150,000</strong> of your account balance' },
              ]
            },
            {
              title: 'New User',
              items: [
                { index: 1, text: '<strong class="num">S$120</strong> - Apply for two eligible UOB savings accounts online and deposit at least S$3,000 in new funds into each new account' },
              ]
            },
          ],
          realisticRate: '3.38%',
          estimatedInterest: 'S$286.64',
          applyNowLink: 'https://www.uob.com.sg/personal/save/everyday-accounts/one-account.page'
        },
        {
          id: 5,
          logo: 'hsbc.png',
          title: 'Everyday Global Account (EGA)',
          conditions: [
            { title: 'DEPOSIT', value: 'Deposit at least S$2,000.' },
            { title: 'TRANSACT', value: 'Make at least 5 eligible transactions.' },
            { title: '', value: '' },
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: 'To register, send an SMS to 74722, EGA<Space>first 9-digit of your Everyday Global Account number (e.g. EGA 123456789)'},
                { index: 2, text: 'Fulfill above two criterias to earn <strong class="num">1.00%</strong> p.a. interest on the Top-up amount against the ADB for the previous calendar month.' },
                { index: 3, text: '<strong class="num">3.10%</strong> p.a. interest on the Top-up amount against the ADB in the <strong>Reference Month (Sep 2024)</strong>' },
                { index: 4, text: 'Bonus Interest will be credited into an Eligible Account in SGD by <strong class="num"> 28 February 2025</strong>' },
              ]
            },
          ],
          realisticRate: '3.10%',
          estimatedInterest: 'S$263.29',
          applyNowLink: 'https://www.hsbc.com.sg/accounts/products/everyday-global'
        },
        {
          id: 6,
          logo: 'cimb.png',
          title: 'FastSaver Account',
          conditions: [
            { title: 'SALARY<i class="fa-solid fa-money-bill-transfer"></i>', value: 'Credit your salary of min. S$1,000 to earn extra 0.5%.' },
            { title: '', value: '' },
            { title: '', value: '' }
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
              { index: 1, text: '<strong class="num">3.30%</strong> p.a. interest rate on the incremental fresh funds till <strong class="num">31 December 2024</strong> if topping up at least <strong class="num">S$5,000</strong> compared to month-end balance of <strong class="num">30 September 2024</strong>' },
              { index: 2, text: '<strong class="num">0.50%</strong> p.a. additional interest on the first <strong class="num">S$25,000</strong> if you credit salary or schedule a recurring GIRO transfer of at least <strong class="num">S$1,000</strong>' },
              ]
            },
            {
              title: 'New User',
              items: [
                { index: 1, text: 'Open account from 1 to 31 October 2024, deposit and maintain a minimum of <strong class="num">S$5,000</strong>'},
                { index: 2, text: '<strong class="num">0.50%</strong> p.a. interest on the first <strong class="num">S$25,000</strong> balance with a minimum salary credit or scheduled recurring transfers (GIRO) of <strong class="num">S$1,000</strong>' },
                { index: 3, text: '<strong class="num">1.00%</strong> p.a. interest on the first <strong class="num">S$25,000</strong> balance with a minimum <strong class="num">S$300</strong> on eligible card spends or <br/><strong class="num">1.50%</strong> p.a. interest on the first <strong class="num">S$25,000</strong> balance ftill <strong>30 November 2024</strong> with a minimum <strong class="num">S$800</strong> on eligible card spends' },
              ]
            },
          ],
          realisticRate: '3.52%',
          estimatedInterest: 'S$399.38',
          applyNowLink: 'https://www.cimb.com.sg/en/personal/banking-with-us/accounts/savings-accounts/cimb-fastsaver-account.html'
        },
        {
          id: 7,
          logo: 'chocolate_fin.png',
          title: 'Chocolate Finance Account',
          conditions: [
            { title: '', value: '' },
            { title: '', value: '' },
            { title: '', value: '' },
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: '<strong class="num">4.20%</strong> p.a. interest for first <strong class="num">S$20,000</strong> account balance' },
                { index: 2, text: 'Target <strong class="num">3.50%</strong> p.a. interest for above <strong class="num">S$20,000</strong> account balance' },
              ]
            }
          ],
          realisticRate: '3.64%',
          estimatedInterest: 'S$309.15',
          applyNowLink: 'https://share.chocolate.app/nxW9/6l8z925a'
        },
        {
          id: 8,
          logo: 'maribank.png',
          title: 'Savings Account',
          conditions: [
            { title: '', value: '' },
            { title: '', value: '' },
            { title: '', value: '' },
          ],
          tabs: [
            {
              title: 'New User',
              items: [
                { index: 1, text: '<strong class="num">2.70%</strong> p.a. interest for first <strong class="num">S$100,000</strong> account balance is effective only till 31 Dec 2024' },
                { index: 2, text: '<strong class="num">S$5</strong> - Account opening using referral code <strong class="num">[9KIN92UQ]</strong>' }
              ]
            }
          ],
          realisticRate: '2.70%',
          estimatedInterest: 'S$229.31',
          applyNowLink: 'https://www.maribank.sg'
        },
        {
          id: 9,
          logo: 'trust.png',
          title: 'Savings Account',
          conditions: [
            { title: 'SALARY', value: 'Credit your salary of min. S$1,500.' },
            { title: 'SPEND', value: 'Charge your Trust card at least 5 x min. S$30 spends.' },
            { title: '', value: '' },
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: '<strong class="num">1.25%</strong> p.a. base interest' },
                { index: 2, text: '<strong class="num">0.75%</strong> p.a. interest with a minimum salary credit of <strong class="num">S$1,500</strong> per month' },
                { index: 3, text: '<strong class="num">1.00%</strong> p.a. interest with at least 5 minimum of <strong class="num">S$30</strong> on Trust card spends per month' },
                { index: 4, text: '<strong class="num">0.50%</strong> p.a. interest when you maintain a minimun <strong class="num">S$100,000</strong> ADB' },
              ]
            },
            {
              title: 'New User',
              items: [
                { index: 1, text: '<strong class="num">S$10</strong> FairPrice E-voucher - Account opening using referral code <strong class="num">[DZZ6FDDC]</strong>' }
              ]
            },
          ],
          realisticRate: '3.00%',
          estimatedInterest: 'S$254.79',
          applyNowLink: 'https://trustbank.sg'
        },
        {
          id: 10,
          logo: 'uob.png',
          title: 'Stash Account',
          conditions: [
            { title: '', value: '' },
            { title: '', value: '' },
            { title: '', value: '' },
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: 'Maintain or increase your Monthly Average Balance (MAB), as compared to the previous month'},
                { index: 2, text: '<strong class="num">0.05%</strong> p.a. interest for first <strong class="num">S$10,000</strong> account balance' },
                { index: 3, text: '<strong class="num">2.00%</strong> p.a. interest for next <strong class="num">S$30,000</strong> account balance' },
                { index: 4, text: '<strong class="num">3.00%</strong> p.a. interest for next <strong class="num">S$30,000</strong> account balance' },
                { index: 5, text: '<strong class="num">5.00%</strong> p.a. interest for next <strong class="num">S$50,000</strong> account balance' },
                { index: 6, text: '<strong class="num">0.05%</strong> p.a. interest for above <strong class="num">S$100,000</strong> account balance' }
              ]
            }
          ],
          realisticRate: '3.00%',
          estimatedInterest: 'S$255.22',
          applyNowLink: 'https://www.uob.com.sg/personal/save/savings-accounts/stash-account.page'
        },
        {
          id: 11,
          logo: 'sc.png',
          title: 'e$aver Account',
          conditions: [
            { title: '', value: '' },
            { title: '', value: '' },
            { title: '', value: '' },
          ],
          tabs: [
            {
              title: 'Existing User',
              items: [
                { index: 1, text: '<strong class="num">3.35%</strong> p.a. interest for incremental fresh funds compared to September 2024 average daily balance' },
                { index: 2, text: 'Promotion - <strong class="num">1 October 2024</strong> to <strong class="num">30 November 2024</strong>' },
              ]
            }
          ],
          realisticRate: '3.35%',
          estimatedInterest: 'S$297.26',
          applyNowLink: 'https://www.sc.com/sg/save/savings-accounts/esaver'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
