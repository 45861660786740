import { createApp } from 'vue'
import BootstrapVue3 from 'bootstrap-vue-3';
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/css/styles.css'

// Create Vue app instance
const app = createApp(App);

// Use router and BootstrapVue3
app.use(router);
app.use(BootstrapVue3);

// Mount the app
app.mount('#app');