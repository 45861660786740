<template>
    <footer class="footer">
      <p class="text-muted">
        <i class="fas fa-code"></i> by <a href="https://wynntech.me"><strong>wynn@tech</strong></a>
      </p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer',
  }
  </script>
  
  