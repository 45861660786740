<template>
  <div class="top-section">
    <h2>Money Calculator</h2>
    <p class="text-muted">Let's see how much your cash can grow!</p>
  </div>
  <div class="main-content">
    <!-- Tab Navigation using VueBootstrap Tabs -->
    <b-tabs v-model="activeTab" card>
      <b-tab title="Interest Calculator" active>
        <div class="tab-content">
          <form @submit.prevent="calculateInterest">
            <div class="form-group">
              <label>💰 How much dough do you have in the account?</label>
              <input
                type="number"
                v-model.number="form.accountBalance"
                class="form-control"
                placeholder="Enter your account balance"
                required
              />
            </div>

            <div class="form-group">
              <label>💸 How much moolah hits your account as a salary every month?</label>
              <input
                type="number"
                v-model.number="form.salary"
                class="form-control"
                placeholder="Enter your monthly salary credit"
                required
              />
            </div>

            <div class="form-group">
              <label>🛍️ How much can you splurge each month?</label>
              <input
                type="number"
                v-model.number="form.spend"
                class="form-control"
                placeholder="Enter your monthly spending amount"
                required
              />
            </div>

            <div class="form-group">
              <label>🤑 How much can you pump up your balance?</label>
              <input
                type="number"
                v-model.number="form.additionalDeposit"
                class="form-control"
                placeholder="Enter additional deposit amount"
              />
            </div>

            <div class="form-group">
              <label>💵 I can handle ___ bill payments with each at least ___ each</label>
              <div class="d-flex">
                <input
                  type="number"
                  v-model.number="form.numberOfBills"
                  class="form-control"
                  placeholder="Number of bills"
                  required
                />
                <input
                  type="number"
                  v-model.number="form.billAmount"
                  class="form-control"
                  placeholder="Minimum amount per bill"
                  required
                />
              </div>
            </div>

            <div class="form-group">
              <b-form-checkbox v-model="form.transactions">
                <label> 💳 I’m a pro at moving money—at least 5 transactions a month and counting! </label>
              </b-form-checkbox>
            </div>

            <div class="form-group">
              <b-form-checkbox v-model="form.invest">
               <label> 📈 Got a stash of cash making more cash? (a.k.a. investments) </label>
              </b-form-checkbox>
            </div>

            <div class="form-group">
              <b-form-checkbox v-model="form.insure">
                <label> 🛡️ Got a safety net in the form of insurance? </label>
              </b-form-checkbox>
            </div>


            <button type="submit" class="btn btn-primary">
              Show Me the Money!!
            </button>
          </form>
        </div>

        <!-- Top 3 Bank Savings Accounts Cards -->
        <div class="row mt-5">
          <div class="col-md-4" v-for="(bank, index) in topBanks" :key="index">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <img :src="require(`@/${bank.logo}`)"  alt="Bank Logo" /> {{ bank.name }}
                </h5>
                <div class="card-text">
                  <div class="listing-text">
                    <span class="right-label">REALISTIC RATE</span>
                    <h3>{{ bank.realisticRate }}%</h3>
                  </div>
                  <div class="listing-text">
                    <span class="right-label">ESTIMATED INTEREST</span>
                    <h3>S${{ bank.estimatedInterest }}</h3>
                  </div>
                  <div class="listing-text">
                    <a href="#" class="btn btn-primary" target="_blank" rel="noopener noreferrer">APPLY NOW</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </b-tab>
      <b-tab title="FIRE Calculator">
        <div class="tab-content">
          <form @submit.prevent="calculateFire">
            <!-- Current Age -->
            <div class="form-group">
              <label>🎂 Current Age</label>
              <b-tooltip target="current-age" title="Your current age.">
                <input
                  id="current-age"
                  type="number"
                  v-model.number="fireData.currentAge"
                  class="form-control"
                  placeholder="Enter your current age"
                  required
                />
              </b-tooltip>
            </div>

            <!-- Current Net Worth -->
            <div class="form-group">
              <label>💼 Current Net Worth</label>
              <b-tooltip target="current-net-worth" title="The current value of all your assets, minus the total of all liabilities.">
                <input
                  id="current-net-worth"
                  type="number"
                  v-model.number="fireData.currentNetWorth"
                  class="form-control"
                  placeholder="Enter your current net worth"
                  required
                />
              </b-tooltip>
            </div>

            <!-- Annual Income -->
            <div class="form-group">
              <label>💵 Annual Income</label>
              <b-tooltip target="annual-income" title="Your total annual income from all sources.">
                <input
                  id="annual-income"
                  type="number"
                  v-model.number="fireData.annualIncome"
                  class="form-control"
                  placeholder="Enter your annual income"
                  required
                />
              </b-tooltip>
            </div>

            <!-- Current Annual Expenses -->
            <div class="form-group">
              <label>📉 Current Annual Expenses</label>
              <b-tooltip target="annual-expenses" title="Your total annual living expenses.">
                <input
                  id="annual-expenses"
                  type="number"
                  v-model.number="fireData.annualExpenses"
                  class="form-control"
                  placeholder="Enter your current annual expenses"
                  required
                />
              </b-tooltip>
            </div>

            <!-- Annual Savings Rate -->
            <div class="form-group">
              <label>💹 Annual Savings Rate (%)</label>
              <b-tooltip target="savings-rate" title="The percentage of your annual income that you save each year.">
                <input
                  id="savings-rate"
                  type="number"
                  v-model.number="fireData.savingsRate"
                  class="form-control"
                  placeholder="Enter your annual savings rate in percentage"
                  required
                />
              </b-tooltip>
            </div>

            <!-- Asset Allocation -->
            <h5>📊 Asset Allocation</h5>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Stocks (%)</label>
                <b-tooltip target="stocks-allocation" title="Percentage of your net worth invested in stocks.">
                  <input
                    id="stocks-allocation"
                    type="number"
                    v-model.number="fireData.assetAllocation.stocks"
                    class="form-control"
                    placeholder="Stocks allocation (%)"
                    required
                  />
                </b-tooltip>
              </div>
              <div class="form-group col-md-4">
                <label>Bonds (%)</label>
                <b-tooltip target="bonds-allocation" title="Percentage of your net worth invested in bonds.">
                  <input
                    id="bonds-allocation"
                    type="number"
                    v-model.number="fireData.assetAllocation.bonds"
                    class="form-control"
                    placeholder="Bonds allocation (%)"
                    required
                  />
                </b-tooltip>
              </div>
              <div class="form-group col-md-4">
                <label>Cash (%)</label>
                <b-tooltip target="cash-allocation" title="Percentage of your net worth held in cash.">
                  <input
                    id="cash-allocation"
                    type="number"
                    v-model.number="fireData.assetAllocation.cash"
                    class="form-control"
                    placeholder="Cash allocation (%)"
                    required
                  />
                </b-tooltip>
              </div>
            </div>

            <!-- Expected Rate of Return -->
            <h5>💵 Expected Rate of Return</h5>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Stocks (%)</label>
                <b-tooltip target="stocks-return" title="Expected annual return rate for stocks, after accounting for inflation.">
                  <input
                    id="stocks-return"
                    type="number"
                    v-model.number="fireData.expectedReturn.stocks"
                    class="form-control"
                    placeholder="Stocks return (%)"
                    required
                  />
                </b-tooltip>
              </div>
              <div class="form-group col-md-4">
                <label>Bonds (%)</label>
                <b-tooltip target="bonds-return" title="Expected annual return rate for bonds, after accounting for inflation.">
                  <input
                    id="bonds-return"
                    type="number"
                    v-model.number="fireData.expectedReturn.bonds"
                    class="form-control"
                    placeholder="Bonds return (%)"
                    required
                  />
                </b-tooltip>
              </div>
              <div class="form-group col-md-4">
                <label>Cash (%)</label>
                <b-tooltip target="cash-return" title="Expected annual return rate for cash, after accounting for inflation.">
                  <input
                    id="cash-return"
                    type="number"
                    v-model.number="fireData.expectedReturn.cash"
                    class="form-control"
                    placeholder="Cash return (%)"
                    required
                  />
                </b-tooltip>
              </div>
            </div>

            <!-- Annual Withdrawal Rate -->
            <div class="form-group">
              <label>🏠 Annual Withdrawal Rate (%)</label>
              <b-tooltip target="withdrawal-rate" title="The rate at which you plan to withdraw from your savings during retirement.">
                <input
                  id="withdrawal-rate"
                  type="number"
                  v-model.number="fireData.withdrawalRate"
                  class="form-control"
                  placeholder="Enter your expected withdrawal rate (typically 4%)"
                  required
                />
              </b-tooltip>
            </div>

            <button type="submit" class="btn btn-primary">
              Calculate FIRE
            </button>
          </form>

          <div v-if="fireResult" class="result-section mt-4">
            <p>🎉 You can reach financial independence in  <span class="">{{ fireResult.years }}</span> years at the age of  <span>{{ fireResult.age }}</span>!</p>
            <p>Your estimated net worth at that time will be: <span>S${{ fireResult.finalNetWorth.toFixed(2) }}</span></p>
          </div>
        </div>
      </b-tab>
      <b-tab title="OCBC 360 Save Calculator">
        <div class="tab-content">
          <form>
            <div class="form-group">
              <label for="availableBalance">Available Balance ($):</label>
              <input
                type="number"
                id="availableBalance"
                v-model.number="availableBalance"
                class="form-control"
                placeholder="Enter Available Balance"
              />
            </div>

            <div class="form-group">
              <label for="currentAvgDailyBalance">Current Month Average Daily Balance ($):</label>
              <input
                type="number"
                id="currentAvgDailyBalance"
                v-model.number="currentAvgDailyBalance"
                class="form-control"
                placeholder="Enter Current Avg Daily Balance"
              />
            </div>

            <div class="form-group">
              <label for="avgDailyIncrease">Average Daily Balance Increase vs. Last Month ($):</label>
              <input
                type="number"
                id="avgDailyIncrease"
                v-model.number="avgDailyIncrease"
                class="form-control"
                placeholder="Enter Average Daily Balance Increase"
              />
            </div>

            <div class="form-group">
              <label for="transactionDays">Transaction Days (as of):</label>
              <input
                type="number"
                id="transactionDays"
                v-model.number="transactionDays"
                class="form-control"
                placeholder="Enter Transaction Days"
              />
            </div>

            <div class="form-group">
              <label for="daysInMonth">No. of Days in This Month:</label>
              <input
                type="number"
                id="daysInMonth"
                v-model.number="daysInMonth"
                class="form-control"
                placeholder="Days in Current Month"
              />
            </div>

            <div class="form-group">
              <label>Required Balance for Remaining Days ($):</label>
              <input
                type="number"
                :value="requiredBalance.toFixed(2)"
                class="form-control"
                disabled
              />
            </div>

            <div class="form-group">
              <label>Top-Up Amount ($):</label>
              <input
                type="number"
                :value="topUpAmount.toFixed(2)"
                class="form-control"
                disabled
              />
            </div>
          </form>
        </div>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
export default {
  name: "Calculator",
  computed: {
    requiredBalance() {
      const { currentAvgDailyBalance, avgDailyIncrease, transactionDays, daysInMonth } = this;
      const targetAvgIncrease = 500; // Target average increase vs last month
      if (daysInMonth > transactionDays) {
        return (
          ((currentAvgDailyBalance + (targetAvgIncrease - avgDailyIncrease)) * daysInMonth -
            currentAvgDailyBalance * transactionDays) /
          (daysInMonth - transactionDays)
        );
      }
      return 0;
    },
    topUpAmount() {
      const { availableBalance, requiredBalance } = this;
      return requiredBalance > 0 ? requiredBalance - availableBalance : 0;
    },
  },
  data() {
    return {
      availableBalance: 0, 
      currentAvgDailyBalance: 0, 
      avgDailyIncrease: 0, 
      transactionDays: 0,
      daysInMonth: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(), 
      fireData: {
        currentAge: 35, // example default value
        currentNetWorth: 400000, // example default value
        annualIncome: 80000, // example default value
        annualExpenses: 36000, // example default value
        savingsRate: 100, // example default value
        withdrawalRate: 4, // example default value
        assetAllocation: {
          stocks: 0, // percentage allocation to stocks
          bonds: 0, // percentage allocation to bonds
          cash: 100, // percentage allocation to cash
        },
        expectedReturn: {
          stocks: 0, // expected return rate for stocks
          bonds: 0, // expected return rate for bonds
          cash: 3, // expected return rate for cash
        },
      },
      fireResult: null,
      activeTab: 0,
      form: {
        accountBalance: 0,
        salary: 0,
        spend: 0,
        additionalDeposit: 0,
        numberOfBills: 0,
        transactions: false,
        billAmount: 0,
        invest: false,
        insure: false,
      },
      banks: [
        {
          id: 1,
          name: "360 Account",
          logo: "assets/icons/ocbc.png",
          calculateInterest: this.calculateOCBCInterest,
          estimatedInterest: 0,
          realisticRate: 0,
        },
        {
          id: 2,
          name: "Bonus$aver Account",
          logo: "assets/icons/sc.png",
          calculateInterest: this.calculateSCInterest,
          estimatedInterest: 0,
          realisticRate: 0,
        },
        {
          id: 3,
          name: "SmartSaver Account",
          logo: "assets/icons/boc.png",
          calculateInterest: this.calculateBOCInterest,
          estimatedInterest: 0,
          realisticRate: 0,
        },
        {
          id: 4,
          name: "One Account",
          logo: "assets/icons/uob.png",
          calculateInterest: this.calculateUOBInterest,
          estimatedInterest: 0,
          realisticRate: 0,
        },
        {
          id: 5,
          name: "Everyday Global Account (EGA)",
          logo: "assets/icons/hsbc.png",
          calculateInterest: this.calculateHSBCInterest,
          estimatedInterest: 0,
          realisticRate: 0,
        },
        // Add more banks here...
      ],
      topBanks: [],
    };
  },
  methods: {
    calculateFire() {
      const {
        currentAge,
        currentNetWorth,
        annualIncome,
        annualExpenses,
        savingsRate,
        withdrawalRate,
        assetAllocation,
        expectedReturn,
      } = this.fireData;

      // Convert percentages to decimals
      const savingsRateDecimal = savingsRate / 100;
      const withdrawalRateDecimal = withdrawalRate / 100;

      // Calculate annual savings
      const annualSavings = annualIncome * savingsRateDecimal;

      // Calculate the FIRE target (net worth needed to sustain annual expenses)
      const fireTarget = annualExpenses / withdrawalRateDecimal;

      let netWorth = currentNetWorth;
      let age = currentAge;
      let yearsToFire = 0;

      // Simulate each year until the FIRE target is reached
      while (netWorth < fireTarget) {
        console.log(netWorth + " " + fireTarget)
        // Calculate annual return based on asset allocation
        const stocksReturn = (assetAllocation.stocks / 100) * expectedReturn.stocks / 100;
        const bondsReturn = (assetAllocation.bonds / 100) * expectedReturn.bonds / 100;
        const cashReturn = (assetAllocation.cash / 100) * expectedReturn.cash / 100;
        const totalReturn = stocksReturn + bondsReturn + cashReturn;

        netWorth = netWorth * (1 + totalReturn) + annualSavings;
        yearsToFire++;
        age++;
      }

      this.fireResult = {
        years: yearsToFire,
        age: age,
        finalNetWorth: netWorth,
      };
    },
    calculateInterest() {
      this.banks.forEach((bank) => {
        bank.calculateInterest();
      });

      this.sortTopBanks();
    },
    sortTopBanks() {
      this.topBanks = [...this.banks]
        .sort((a, b) => b.realisticRate - a.realisticRate)
        .slice(0, 3); // Get the top 3 banks
    },
    calculateOCBCInterest() {
      let balance = this.form.accountBalance;
      let salary = this.form.salary;
      let spend = this.form.spend;
      let additionalDeposit = this.form.additionalDeposit;
      let invest = this.form.invest;
      let insure = this.form.insure;

      let interest = 0;
      let maxBonusBalance = 100000;
      let baseInterestRate = 0.0005; // 0.05% for amounts beyond 100k unless other conditions apply
      let eligibleBalance = Math.min(balance, maxBonusBalance);
      let remainingBalance = balance - eligibleBalance;

      // Rule 1: Salary Credit
      if (salary >= 1800) {
        if (eligibleBalance > 75000) {
          interest += 75000 * 0.02 + (eligibleBalance - 75000) * 0.04;
        } else {
          interest += eligibleBalance * 0.02;
        }
      }

      // Rule 2: Additional Deposit
      if (additionalDeposit >= 500) {
        if (eligibleBalance > 75000) {
          interest += 75000 * 0.012 + (eligibleBalance - 75000) * 0.024;
        } else {
          interest += eligibleBalance * 0.012;
        }
      }

      // Rule 3: Card Spending
      if (spend >= 500) {
        interest += eligibleBalance * 0.006;
      }

      // Rule 4: Insurance Policy
      if (insure) {
        if (eligibleBalance > 75000) {
          interest += 75000 * 0.012 + (eligibleBalance - 75000) * 0.024;
        } else {
          interest += eligibleBalance * 0.012;
        }
      }

      // Rule 5: Unit Trust or Structured Deposits
      if (invest) {
        if (eligibleBalance > 75000) {
          interest += 75000 * 0.012 + (eligibleBalance - 75000) * 0.024;
        } else {
          interest += eligibleBalance * 0.012;
        }
      }

      // Rule 6: Large Balance (>= 200k)
      if (balance >= 200000) {
        remainingBalance = balance - maxBonusBalance;
        interest += remainingBalance * 0.024;
      }

      interest += balance * baseInterestRate;

      let realisticRate = interest / balance * 100;
      let monthlyInterest = interest / 365 * 31;

      this.banks = this.banks.map(bank => {
        if (bank.id === 1) {
          return {
            ...bank,
            realisticRate: `${realisticRate.toFixed(2)}`,
            estimatedInterest: `${monthlyInterest.toFixed(2)}` 
          };
        }
        return bank;
      });
      console.log("Calculated OCBC Interest:", realisticRate.toFixed(2));
      console.log("Calculated OCBC Interest:", monthlyInterest.toFixed(2));
    },
    calculateSCInterest() {
      let balance = this.form.accountBalance;
      let salary = this.form.salary;
      let spend = this.form.spend;
      let numberOfBills = this.form.numberOfBills;
      let billAmount = this.form.billAmount;
      let invest = this.form.invest;
      let insure = this.form.insure;

      let interest = 0;
      let maxBonusBalance = 100000;
      let baseInterestRate = 0.0005; // 0.05% for amounts beyond 100k unless other conditions apply
      let eligibleBalance = Math.min(balance, maxBonusBalance);

      // Rule 1: Salary Credit
      if (salary >= 3000) {
        interest += eligibleBalance * 0.02;
      }

      // Rule 2: Card Spending
      if (spend >= 2000) {
        interest += eligibleBalance * 0.014; // 1.40% p.a. interest
      } else if (spend >= 500) {
        interest += eligibleBalance * 0.006; // 0.60% p.a. interest
      }

      // Rule 3: Bill Payments
      if (numberOfBills >= 3 && billAmount >= 50) {
        interest += eligibleBalance * 0.0023; // 0.23% p.a. interest
      }

      // Rule 4: Investment (Eligible Unit Trust)
      if (invest) {
        interest += eligibleBalance * 0.02; // 2.00% p.a. interest
      }

      // Rule 5: Insurance Policy
      if (insure) {
        interest += eligibleBalance * 0.0023; // 0.23% p.a. interest
      }

      interest += balance * baseInterestRate;

      let realisticRate = (interest / balance) * 100;
      let monthlyInterest = (interest / 365) * 31;
      this.banks = this.banks.map(bank => {
        if (bank.id === 2) {
          return {
            ...bank,
            realisticRate: `${realisticRate.toFixed(2)}`,
            estimatedInterest: `${monthlyInterest.toFixed(2)}` 
          };
        }
        return bank;
      });
      console.log("Calculated SC Interest:", realisticRate.toFixed(2));
      console.log("Calculated SC Interest:", monthlyInterest.toFixed(2));
    },
    calculateBOCInterest() {
      let balance = this.form.accountBalance;
      let salary = this.form.salary;
      let spend = this.form.spend;
      let numberOfBills = this.form.numberOfBills;
      let billAmount = this.form.billAmount;
      let insure = this.form.insure;

      let interest = 0;
      let maxBonusBalance = 100000;
      let baseInterest = 0;
      let eligibleBalance = Math.min(balance, maxBonusBalance);

      // Rule 1: Salary Credit
      if (salary >= 6000) {
        interest += eligibleBalance * 0.025; // 2.5% p.a.
      } else if (salary >= 2000) {
        interest += eligibleBalance * 0.019; // 1.9% p.a.
      }

      // Rule 2: Card Spending
      if (spend >= 1500) {
        interest += eligibleBalance * 0.008; // 0.8% p.a.
      } else if (spend >= 500) {
        interest += eligibleBalance * 0.005; // 0.5% p.a.
      }

      // Rule 3: Bill Payments
      if (numberOfBills >= 3 && billAmount >= 30) {
        interest += eligibleBalance * 0.009; // 0.9% p.a.
      }

      // Rule 4: Insurance Policy
      if (insure) {
        interest += eligibleBalance * 0.024; // 2.4% p.a.
      }

      // Rule 5: Base Interest Rate on all balances (tiered)
      if (balance > 100000) {
        baseInterest += 100000 * 0.004; // 0.4% p.a. for the first 100k
        baseInterest += (balance - 100000) * 0.006; // 0.6% p.a. for balance above 100k
      } else if (balance > 50000) {
        baseInterest += balance * 0.004; // 0.4% p.a.
      } else if (balance > 20000) {
        baseInterest += balance * 0.003; // 0.3% p.a.
      } else if (balance > 5000) {
        baseInterest += balance * 0.002; // 0.2% p.a.
      } else {
        baseInterest += balance * 0.0015; // 0.15% p.a.
      }

      // Adding base interest to the total interest
      interest += baseInterest;

      let realisticRate = (interest / balance) * 100;
      let monthlyInterest = (interest / 365) * 31;
      
      // Update the BOC entry in the banks array
      this.banks = this.banks.map(bank => {
        if (bank.id === 3) {
          return {
            ...bank,
            realisticRate: `${realisticRate.toFixed(2)}`,
            estimatedInterest: `${monthlyInterest.toFixed(2)}` 
          };
        }
        return bank;
      });
      
      console.log("Calculated BOC Interest:", realisticRate.toFixed(2));
      console.log("Calculated BOC Monthly Interest:", monthlyInterest.toFixed(2));
    },
    calculateUOBInterest() {
      let balance = this.form.accountBalance;
      let salary = this.form.salary;
      let spend = this.form.spend;

      let interest = 0;
      let maxBonusBalance = 150000;
      let eligibleBalance = Math.min(balance, maxBonusBalance);
      let baseInterestRate = 0.0005; // 0.05% for amounts beyond 150k

      // Check if user meets the conditions for bonus interest
      if (salary >= 1600 && spend >= 500) {
        // Calculate bonus interest based on balance tiers
        if (eligibleBalance > 100000) {
          interest += 25000 * 0.06; // 6.00% p.a. for next S$25,000
          interest += 50000 * 0.045; // 4.50% p.a. for next S$50,000
          interest += 75000 * 0.03;  // 3.00% p.a. for first S$75,000
        } else if (eligibleBalance > 50000) {
          interest += 25000 * 0.06; // 6.00% p.a. for next S$25,000
          interest += (eligibleBalance - 50000) * 0.045; // 4.50% p.a. for remaining balance
        } else if (eligibleBalance > 25000) {
          interest += (eligibleBalance - 25000) * 0.06; // 6.00% p.a. for remaining balance
          interest += 25000 * 0.03; // 3.00% p.a. for first S$25,000
        } else {
          interest += eligibleBalance * 0.03; // 3.00% p.a. for balance up to S$25,000
        }
      }
        
      interest += balance * baseInterestRate;

      let realisticRate = (interest / balance) * 100;
      let monthlyInterest = (interest / 365) * 31;

      this.banks = this.banks.map(bank => {
        if (bank.id === 4) { 
          return {
            ...bank,
            realisticRate: `${realisticRate.toFixed(2)}`,
            estimatedInterest: `${monthlyInterest.toFixed(2)}`
          };
        }
        return bank;
      });
      console.log("Calculated UOB Interest:", realisticRate.toFixed(2));
      console.log("Calculated UOB Monthly Interest:", monthlyInterest.toFixed(2));
    },
    calculateHSBCInterest() {
      let balance = this.form.accountBalance;
      let additionalDeposit = this.form.additionalDeposit;
      let transactions = this.form.transactions;

      let baseInterestRate = 0.031; // 3.1% p.a.
      let extraInterestRate = 0;

      if (transactions && additionalDeposit > 2000) {
        extraInterestRate = 0.01; // Extra 1% p.a.
      }

      let totalInterestRate = baseInterestRate + extraInterestRate;
      let interest = balance * totalInterestRate;

      let monthlyInterest = (interest / 365) * 31;

      let realisticRate = (totalInterestRate * 100);
      this.banks = this.banks.map(bank => {
        if (bank.id === 5) { 
          return {
            ...bank,
            realisticRate: `${realisticRate.toFixed(2)}`, // Annualized rate
            estimatedInterest: `${monthlyInterest.toFixed(2)}`
          };
        }
        return bank;
      });

      console.log("Calculated HSBC Interest Rate:", realisticRate.toFixed(2));
      console.log("Calculated HSBC Monthly Interest:", monthlyInterest.toFixed(2));
    }

  }
};
</script>

<style scoped>
.tabs {
  margin-bottom: 20px;
  width: 100%
}
.tab-content {
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
}

.d-flex {
  display: flex;
  gap: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
}

.col {
  flex: 1;
  margin: 0 10px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.card-body {
  padding: 10px;
}

.card-title {
  font-size: 18px;
  margin-bottom: 10px;
  min-height: 80px;
  align-content: center;
}

.card-title img {
  max-height: 55px;
}

.card-rate {
  font-size: 16px;
  color: #007bff;
}
</style>
